<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="formId">
                {{ sprintf($t('pages.module.form.save.editForm'), [form.translate.title ?? '']) }}
                <a class="btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 ms-1" :href="$root.appUrl + '/' + form.translate.slug" target="_blank">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="/media/icons/duotune/coding/cod007.svg"/>
                    </span>
                </a>
            </template>

            <template v-else>{{ $t('pages.module.form.save.newForm') }}</template>
        </h2>

        <div class="d-flex flex-wrap my-1">
            <router-link to="/module/form" class="btn btn-primary align-self-center ms-4 back-page-btn">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                </span>
                {{ $t("pages.module.form.title") }}
            </router-link>
        </div>
    </div>

    <el-tabs v-model="tabModel" class="form-tabs">
        <el-tab-pane :label="$t('pages.module.form.save.tabs.general')" name="general">
            <el-form :model="form" ref="formModuleForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.form.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.title" type="text"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.form.save.cols.description') }}</label>
                                    <el-form-item prop="translate.content">
                                        <CustomCkeditor :model="form.translate.description" @updateModel="form.translate.description = $event" :enterToBr="true"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-7">
                            <div class="card-header pt-4 pb-4">
                                <h3 class="card-title align-items-start flex-column">
                                    {{ $t('pages.module.blog.save.seoSettings') }}
                                </h3>
                            </div>

                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.form.save.seoCols.slug') }}</label>
                                    <el-form-item prop="translate.meta.slug">
                                        <el-input v-model="form.translate.slug" type="text"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.form.save.seoCols.title') }}</label>
                                    <el-form-item prop="translate.meta.title">
                                        <el-input v-model="form.translate.meta.title" type="text"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.form.save.seoCols.description') }}</label>
                                    <el-form-item prop="translate.meta.description">
                                        <el-input v-model="form.translate.meta.description" type="textarea" rows="3"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.form.save.seoCols.keywords') }}</label>
                                    <el-form-item prop="translate.meta.keyword">
                                        <el-input v-model="form.translate.meta.keyword" type="textarea" rows="3"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.form.save.cols.image') }}</label>

                                    <el-form-item prop="image_id">
                                        <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage" list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                            <i class="bi bi-plus"/>
                                        </el-upload>
                                        <el-dialog v-model="image.dialogVisible" width="30%">
                                            <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                        </el-dialog>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">
                                        {{ $t('pages.module.form.save.cols.answer_after_redirect') }}
                                        <el-tooltip class="item" effect="dark" popper-class="max-w-300px" :content="$t('pages.module.form.save.informationBoxes.answer_after_redirect')" placement="top">
                                            <span class="svg-icon svg-icon-1">
                                                <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                            </span>
                                        </el-tooltip>
                                    </label>

                                    <el-form-item prop="options.answer_after_redirect_status">
                                        <el-radio-group v-model="form.options.answer_after_redirect_status">
                                            <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="form.options.answer_after_redirect_status" >
                                    <label class="fs-6 fw-bold mb-2 required">{{ $t('pages.module.form.save.cols.answer_after_redirect_url') }}</label>
                                    <el-form-item prop="data.options.answer_after_redirect_url" :rules="$validation.getMessage(['required'])">
                                        <el-input type="text" v-model="form.data.options.answer_after_redirect_url" />
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>

        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.form.save.tabs.questions')" name="questions">
            <FormElement :formId="formId" v-if="tabModel === 'questions'"></FormElement>
        </el-tab-pane>

        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.form.save.tabs.replies')" name="replies">
            <FormAnswer :formId="formId" v-if="tabModel === 'replies'"></FormAnswer>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor";
import FormAnswer from "@/components/module/form/answer";
import FormElement from "@/components/module/form/element";

export default {
    name: "_id",
    components: {
        CustomCkeditor,
        FormAnswer,
        FormElement
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id,
                    meta: {}
                },
                data: {
                    options: {},
                    active: true
                },
                options: {
                    answer_after_redirect_status: false
                }
            },
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        }
    },
    computed: {
        formId() {
            return this.$route.params.id;
        },
        languages() {
            let languages = this.cloneData(this.$root.languages);
            return languages.reverse();
        }
    },
    created() {
        if (this.formId && !(this.formId > 0)) {
            this.$router.push({
                path: "/module/form"
            });
        }

        this.eventBus.$on('getCurrentFormId', () => {
            this.eventBus.$emit('setCurrentFormId', this.formId);
        });
    },
    mounted() {
        if (this.formId && this.formId > 0) {
            this.loadFormData();
        }
    },
    methods: {
        loadFormData(formId = this.formId) {
            this.resetImageData();

            this.axios.get(this.endpoints['module_form'] + '/' + formId).then((response) => {
                let data = response.data.data;

                let translateLinks = this.resolveDatum(data.links, this.$root.defaultLanguage.id, 'language_id');
                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                if (data.options instanceof Array) {
                    data.options = {};
                }

                this.form.options.answer_after_redirect_status = !!(data.options && data.options.answer_after_redirect_url);

                this.form.translate = Object.assign(data.translate, {
                    meta: {
                        title: translateLinks.title,
                        description: translateLinks.description,
                        keyword: translateLinks.keyword
                    }
                });

                this.form.updateStatus = true;
                this.form.data = data;

                if (data.image) {
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }
            })
        },
        onSubmit() {
            this.$refs.formModuleForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }

                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            this.form.loading = true;
            let formData = this.prepareFormData();

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_form'] + '/' + this.form.data.id, formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.loadFormData();
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_form'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.$router.push({
                            path: "/module/form/save/" + response.data.data.id
                        });

                        this.loadFormData(response.data.data.id);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        prepareFormData() {
            let formData = this.form.data;

            if (!this.form.options.answer_after_redirect_status) {
                delete formData.options.answer_after_redirect_url;
            }

            return {...this.form.translate, ...formData}
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        }
    }
}
</script>

<style>
.back-page-btn {
    height: 40px;
    line-height: 1.25;
}
</style>