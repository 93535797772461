<template>
    <div class="form-reply-page-header my-5 empty-d-none w-100">
        <router-link v-if="!$root.filterWithUrl && showFormBackButton && formId > 0" to="/module/form" class="btn btn-primary align-self-center float-start">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.module.form.title") }}
        </router-link>

        <div class="float-end" v-if="table.data.length">
            <el-tooltip :content="$t('common.exportTooltip')" popper-class="max-w-300px" placement="bottom">
                <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportAnswer">
                    <span v-if="!exportLoading" class="indicator-label">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                        </span>
                        {{ $t("common.export") }}
                    </span>

                    <span v-if="exportLoading" class="indicator-progress">
                        <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        {{ $t("messages.wait") }}
                    </span>
                </button>
            </el-tooltip>
        </div>
    </div>

    <custom-table
        :title="$t('pages.module.form.answer.title')"
        :subTitle="$t('pages.module.form.answer.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleFormAnswerFilter"
        changeUrlPage
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>

        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.form.answer.detail')" popper-class="max-w-300px" placement="bottom">
                    <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen004.svg"/>
                        </span>
                    </a>
                </el-tooltip>

                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_form_answer_detail" ref="answerDetailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_form_answer_detail_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>

                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_form_answer_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div v-for="(item, index) in form.data.items" :key="item.id">
                            <div class="py-4" :class="{ 'pt-0' : index === 0 }" style="border-bottom: 1px solid #f0f0f0">
                                <b class="fs-6">{{ item.title }}</b>

                                <div class="mt-3" v-if="elementTypes.filter((type) => ['radio_group_table', 'checkbox_group_table'].includes(type.code)).map(item => item.id).includes(item.element.type_id)">
                                    <div class="pl-1 mb-2" v-for="(answerValue, answerValueUUID) in item.value" :key="answerValueUUID">
                                        <b class="fs-7 fw-bold text-gray-800">- {{ answerValue.row }}</b>
                                        <p class="m-0 fw-bold fs-7">&nbsp;&nbsp; {{ typeof answerValue.val === 'object' ? answerValue.val.join(', ') : answerValue.val }}</p>
                                    </div>
                                </div>

                                <div class="mt-1" v-else>
                                    <p class="m-0 fs-7">{{ item.is_json ? item.value.join(', ') : item.value }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="fv-row mt-10">
                            <label class="fs-7 fw-bold mb-2 me-2">{{ $t('pages.module.form.answer.cols.createdAt') }}:</label>
                            <span class="text-gray-700 fw-bold fs-7">{{ $moment(form.data.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                        </div>

                        <div class="fv-row">
                            <label class="fs-7 fw-bold mb-2 me-2">{{ $t('pages.module.form.answer.cols.ip') }}:</label>
                            <span class="text-gray-700 fw-bold fs-7">{{ form.data.ip }}</span>
                        </div>

                        <div class="fv-row">
                            <label class="fs-7 fw-bold mb-2 me-2">{{ $t('pages.module.form.answer.cols.userAgent') }}:</label>
                            <span class="text-gray-700 fw-bold fs-7">{{ form.data.user_agent }}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.answerDetailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table/index.vue";

export default {
    name: "index",
    props: ['formId', 'showFormBackButton'],
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.form.answer.cols.answer_count"),
                    key: "items_count"
                },
                {
                    name: this.$t("pages.module.form.answer.cols.ip"),
                    key: "ip"
                },
                {
                    name: this.$t('pages.module.form.answer.cols.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                data: {}
            },
            selectedRowKeys: [],
            filterParameters: {},
            exportLoading: false
        }
    },
    computed: {
        table() {
            return this.$store.state.module.form.answer.table;
        },
        elementTypes() {
            return this.$store.state.module.form.element.type.table.data;
        }
    },
    mounted() {
        this.filterParameters = {
            sort: 'created_at:desc',
            form_id: this.formId
        };

        this.$store.dispatch('module/form/element/type/get');

        this.$store.dispatch('module/form/answer/get', {
            page: {
                current: this.$route.query.page
            },
            filterData: Object.assign(this.$root.getFilterWithUrl({}), this.filterParameters)
        });
    },
    methods: {
        refreshTable() {
            this.$store.dispatch("module/form/answer/refresh");
        },
        fetchRecord(record) {
            this.form.title = this.$t("pages.module.form.answer.detail");

            this.axios.get(this.endpoints['module_form_answer'] + '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.showModal(this.$refs.answerDetailModal);
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("module/form/answer/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                sort: 'created_at:desc',
                form_id: this.formId
            }, filterData);

            this.$store.dispatch("module/form/answer/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        exportAnswer() {
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['module_form_answer_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style>
.form-reply-page-header:after {
    content: "";
    clear: both;
    display: table;
}
</style>